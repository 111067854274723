import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import RoundedCornerOutlined from '@mui/icons-material/RoundedCornerOutlined'
import { Tooltip } from '@mui/material'

import {
  HeaderToggleButton,
  HeaderToggleButtonGroup,
} from 'src/components/generic/HeaderToggleButtonGroup'

import { useGltfModelStore } from '../../controllers/GltfModelController/gltfModelStore'

export default function OutlineControlsWidget(): ReactElement {
  const { t } = useTranslation(['ifcImporter'])

  const showOutlines = useGltfModelStore(state => state.showOutlines)
  const setShowOutlines = useGltfModelStore(state => state.setShowOutlines)

  return (
    <HeaderToggleButtonGroup>
      <Tooltip title={t('ifcImporter:outlineControls.showGltfOutlines')}>
        <HeaderToggleButton
          value="visible"
          size="small"
          onClick={() => setShowOutlines(!showOutlines)}
          selected={showOutlines}
        >
          <RoundedCornerOutlined fontSize="small" />
        </HeaderToggleButton>
      </Tooltip>
    </HeaderToggleButtonGroup>
  )
}
