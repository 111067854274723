import cloneDeep from 'lodash-es/cloneDeep'
import { Object3D } from 'three'
import create from 'zustand'
import { combine } from 'zustand/middleware'

import { CoordinateSystem } from '../../types'

interface GltfModelStoreState {
  gltfModel: Object3D | null
  isInitialUpload: boolean
  coordinateSystem: CoordinateSystem | null
  gltfModelTranslucent: boolean
  gltfModelNonSelectable: boolean
  showOutlines: boolean
}

const initialState: GltfModelStoreState = {
  gltfModel: null,
  isInitialUpload: false,
  coordinateSystem: null,
  gltfModelTranslucent: false,
  gltfModelNonSelectable: false,
  showOutlines: true,
}

export const useGltfModelStore = create(
  combine(cloneDeep(initialState), set => ({
    clear: () => set(cloneDeep(initialState)),

    setGltfModel: (gltfModel: Object3D | null) =>
      set({
        gltfModel,
      }),

    setIsInitialUpload: (isInitialUpload: boolean) => set({ isInitialUpload }),

    setCoordinateSystem: (coordinateSystem: CoordinateSystem | null) => set({ coordinateSystem }),

    setGltfModelTranslucent: (gltfModelTranslucent: boolean) => set({ gltfModelTranslucent }),

    setGltfModelNonSelectable: (gltfModelNonSelectable: boolean) => set({ gltfModelNonSelectable }),

    setShowOutlines: (showOutlines: boolean) => set({ showOutlines }),
  })),
)
